import {Component, OnInit} from '@angular/core';
import {itemsSocialNetwork} from "@app-core/constants";
import {EMAIL, FOUNDATION_YEAR, PHONE} from 'src/app/core/constants/common';
import {ListItem} from 'src/app/shared/components/list/list.model';


const itemsNav = [
  {
    routerLink: '/about',
    label: 'UI.FOOTER.ABOUT',
  },
  {
    routerLink: '/contacts',
    label: 'UI.FOOTER.CONTACTS',
  },
  {
    routerLink: '/par',
    label: 'UI.FOOTER.PAY_RECEIVE',
  },
];
const itemsContacts = [
  {
    url: `tel:${PHONE}`,
    icon: 'fas fa-phone',
    label: PHONE,
  },
  {
    url: `mailto:${EMAIL}`,
    icon: 'fas fa-envelope',
    label: EMAIL,
  },
];

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public foundationString: string;
  public itemsSocialNetwork: ListItem[];
  public itemsNav: ListItem[];
  public itemsContacts: ListItem[];

  constructor() {
    this.foundationString = `AVIAOPERATOR, © ${FOUNDATION_YEAR} — ${new Date().getFullYear()}`;

    this.itemsSocialNetwork = itemsSocialNetwork;
    this.itemsNav = itemsNav;
    this.itemsContacts = itemsContacts;
  }

  public ngOnInit(): void {}
}
