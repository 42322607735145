import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './auth/auth-callback/auth-callback.component';
import { AuthGuard } from './auth/guards/auth.guard';
// import { RegisterGuard } from './auth/guards/register.guard';
import { LogoutCallbackComponent } from './auth/logout-callback/logout-callback.component';
// import { AuthGuard } from '@app-core/guards/auth.guard';
import { HomePageComponent } from './home-page/home-page.component';

const routes: Routes = [
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'signout-callback', component: LogoutCallbackComponent },
  {
    path: '',
    component: HomePageComponent,
    // canActivate: [RegisterGuard],
    // canActivateChild: [RegisterGuardChild],

    // path: '',
    // component: AuthComponent,

    children: [
      // { path: '', redirectTo: 'booking/1/2', pathMatch: 'full' },
      {
        path: '',
        loadChildren: () =>
          import('./search-page/search-page.module').then(
            (m) => m.SearchPageModule
          ),
        // canActivate: [RegisterGuard],
        data: {
          header: 'PAGE.SEARCH.SEARCH_BLOCK.TITLE',
          seo:'DEFAULT'
        },
      },
      // {
      //   path: 'auth',
      //   loadChildren: () =>
      //     import('./auth/auth.module').then((m) => m.AuthModule),
      // },

      {
        path: 'booking/:variant/:requestId',
        pathMatch: 'full',
        loadChildren: () =>
          import('./booking-page/booking-page.module').then(
            (m) => m.BookingPageModule
          ),
        // canActivate: [RegisterGuard],
        data: {
          header: 'PAGE.BOOKING.TITLE',
          seo:'BOOKING'
        },
      },
      {
        path: 'presearch/:variant',
        pathMatch: 'full',
        loadChildren: () =>
          import('./search-page/search-page.module').then(
            (m) => m.SearchPageModule
          ),
        data: {
          header: 'PAGE.SEARCH.SEARCH_BLOCK.TITLE',
          seo:'SEARCH'
        },
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
        data: {
          seo: 'PROFILE',
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'about',
        loadChildren: () =>
          import('./about/about.module').then((m) => m.AboutModule),
        // canActivate: [RegisterGuard],
        data: {
          header: 'PAGE.ABOUT.TITLE',
          seo:'ABOUT'
        },
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('./contacts/contacts.module').then((m) => m.ContactsModule),
        // canActivate: [RegisterGuard],
        data: {
          header: 'PAGE.CONTACTS.TITLE',
          seo:'CONTACTS'
        },
      },
      // {
      //   path: 'wic',
      //   loadChildren: () =>
      //     import('./what-is-charter/what-is-charter.module').then(
      //       (m) => m.WhatIsCharterModule
      //     ),
      //   // canActivate: [RegisterGuard],
      //   data: {
      //     header: 'PAGE.WIC.TITLE',
      //   },
      // },
      {
        path: 'par',
        loadChildren: () =>
          import('./pay-and-receive/pay-and-receive.module').then(
            (m) => m.PayAndReceiveModule
          ),
        // canActivate: [RegisterGuard],
        data: {
          header: 'PAGE.PAR.TITLE',
          seo:'PAR'
        },
      },
      {
        path: 'payment-callback/:orderId',
        loadChildren: () =>
          import('./order-succesfull/order-succesfull.module').then(
            (m) => m.OrderSuccesfullModule
          ),
      },
      {
        path: 'payment-callback',
        loadChildren: () =>
          import('./order-succesfull/order-succesfull.module').then(
            (m) => m.OrderSuccesfullModule
          ),
      },

      {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
          import('./page404/page404.module').then((m) => m.Page404Module),
      },
    ],
  },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
