import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {ChangeHeader} from '@app-states/layout';
import {Store} from '@ngxs/store';
import {Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {SeoService} from "@app-core/services";
import {Configs} from "@app-core/configs/configs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  readonly isProduction =Configs.production;
  private subject$: Subject<void>;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${event.target.innerHeight}px`);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private readonly seoService: SeoService
  ) {
    this.subject$ = new Subject();
    window.addEventListener('storage', (event) => {

      if (event.key && event.key === 'login') {
        window.location.reload();
      }
    });
  }

  public ngOnInit() {

    //FIXME унести отсюда
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            }  else {
              return child.snapshot.data;
            }
          }
          return {};
        })
      )
      .subscribe((data:Data ) => {
        const customData = {
          header: data['header'],
          subheader: data['subheader']
        };
        this.seoService.setSeoParams(data['seo']);
        this.store.dispatch(new ChangeHeader(customData));
      });

  }

  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }
  public ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }
}
