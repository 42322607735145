import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
// import { AuthGuard } from './guards/auth.guard';
import { LayoutTypeService } from './layout-service/layout.service';
import { LocalStorageService } from './local-storage-service/local-storage.service';
import { API_SERVICES } from './api-services';
import { PayService } from './services/pay.service';
import {SeoService} from "@app-core/services";

@NgModule({ declarations: [], imports: [CommonModule], providers: [
        ...API_SERVICES,
        PayService,
        SeoService,
        LayoutTypeService,
        LocalStorageService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {}
