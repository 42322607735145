import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class RegisterGuard  {
  constructor(private readonly authService: AuthService,private readonly translate: TranslateService) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isAuth().then((isAuth) => {
      if (!isAuth && route.queryParams.state) {
        this.authService.signIn<string>(state.url,this.translate.currentLang);
        return false;
      } else return true;
    });
    // return from(this.authService.isAuth()).pipe(
    //   tap((isAuth) => {
    //     console.log(search.url);

    //     if (!isAuth && route.queryParams.search === 'reg') {
    //       this.authService.signIn<string>(search.url);
    //     }
    //   }),
    //   map((isAuth) => isAuth)
    // );
  }
}
