import {CommonModule} from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgModule} from '@angular/core';
import {NgxsModule} from '@ngxs/store';
import {environment} from 'src/environments/environment';
import {AuthCallbackComponent} from './auth-callback/auth-callback.component';
import {AuthGuard} from './guards/auth.guard';
import {AuthGuardChild} from './guards/auth.guard-child';
import {RegisterGuard} from './guards/register.guard';
import {RegisterGuardChild} from './guards/register.guard-child';
import {HttpConfigInterceptor} from './interceptors/http-config.interceptor';
import {ResponseHandlerInterceptor} from './interceptors/response-handler.interceptor';
import {AUTH_CONFIGS} from './models';
import {AuthService} from './services/auth.service';
import {AuthState} from './state';
import {authConfig} from './utils';
import {WebStorageStateStore} from "oidc-client";
// import { AuthModalComponent } from './auth-modal/auth-modal.component';
// import { SharedModule } from '@shared/shared.module';
// import { TranslateModule } from '@ngx-translate/core';

// @NgModule({
//   declarations: [AuthComponent, AuthModalComponent],
//   imports: [
//     CommonModule,
//     AuthRoutingModule,
//     SharedModule,
//     TranslateModule.forChild({
//       extend: true,
//     }),
//   ],
// })
@NgModule({
  declarations: [AuthCallbackComponent],
  imports: [CommonModule, NgxsModule.forFeature([AuthState])],
  exports: [AuthCallbackComponent],
  providers: [
    AuthGuard,
    AuthGuardChild,
    RegisterGuard,
    RegisterGuardChild,
    AuthService,
    {
      provide: AUTH_CONFIGS,
      useValue: authConfig({
        client_id: environment.authConfig.clientId,
        redirect_uri: environment.authConfig.redirectUri,
        response_type: environment.authConfig.responseType,
        scope: environment.authConfig.scope,
        authority: environment.authConfig.authority,
        post_logout_redirect_uri: environment.authConfig.postLogoutRedirectUri,
        silent_redirect_uri: environment.authConfig.silentRedirectUri,
        automaticSilentRenew: environment.authConfig.automaticSilentRenew,
        loadUserInfo: environment.authConfig.loadUserInfo,
        filterProtocolClaims: environment.authConfig.filterProtocolClaims,
        accessTokenExpiringNotificationTime:
          environment.authConfig.accessTokenExpiringNotificationTime,
        userStore: new WebStorageStateStore({ store: window.localStorage })
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
