import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import {LocalizationApiService} from "@app-core/api-services/localization.service";
import {customTranslateLoader} from "@app-core/classes/custom-trans-loader";
import {
  IS_FULL_VERSION_TOKEN,
  LANGUAGES_KA,
  LANGUAGES_RU,
  LANGUAGES_TOKEN,
  RESOURCES_SERVICE
} from "@app-core/constants";
import { LayoutState } from '@app-states/layout';
import {LayoutStateService} from "@app-states/layout-state.service";
import { ResourcesState } from '@app-states/resources';
import { ResourcesStateService } from '@app-states/resources-state.service';
import { SearchState } from '@app-states/search/search.state';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

import { NgxsModule } from '@ngxs/store';
import { AnnouncementModule } from '@shared/components';
import { LazyLoadImageModule } from '@shared/directives';
import {AngularSvgIconModule} from "angular-svg-icon";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import {
  COMPLETE_AUTHENTICATE_TOKEN,
  COMPLETE_SIGNOUT_TOKEN,
  CompleteFunction,
} from './auth/models';
import { CoreModule } from './core/core.module';
import { FooterComponent } from './home-page/footer/footer.component';
import { HeaderComponent } from './home-page/header/header.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SharedModule } from '@shared/shared.module';

import '@angular/common/locales/global/ru';
import '@angular/common/locales/global/ka';

import * as Sentry from '@sentry/angular';
import { InputMaskModule } from '@ngneat/input-mask';
import { SignalRService } from './socket/signalr.service';
import {ResourcesEnService, ResourcesService} from "@app-core/api-services";
import {ProfileState} from "@app-states/profile/profile.state";


export function completeAuthRedirect(router: Router): CompleteFunction {
  return <TSource>(state: TSource): void => {
    router.navigate([state]);
  };
}

export function completeSignOutRedirect(router: Router): CompleteFunction {
  return <TSource>(state: TSource): void => {
    router.navigate([state]);
  };
}


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    NgxsModule.forRoot([ResourcesState, LayoutState, SearchState,ProfileState], {
      developmentMode: !environment.production,
    }),
    ToastModule,
    BrowserModule,
    BrowserAnimationsModule,
    AnnouncementModule,
    AppRoutingModule,
    CoreModule,
    AngularSvgIconModule.forRoot(),
    LazyLoadImageModule,
    SharedModule,
    AuthModule,
    NgScrollbarModule,
    NgxsDispatchPluginModule.forRoot(),
    TranslateModule.forRoot({
      // defaultLanguage: 'ru',
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoader,
        deps: [LocalizationApiService],
      },
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    InputMaskModule,
  ],
  providers: [
    ResourcesStateService,
    LayoutStateService,
    MessageService,
    SignalRService,
    {
      provide: COMPLETE_AUTHENTICATE_TOKEN,
      useFactory: completeAuthRedirect,
      deps: [Router],
    },
    {
      provide: COMPLETE_SIGNOUT_TOKEN,
      useFactory: completeSignOutRedirect,
      deps: [Router],
    },
    {
      provide: IS_FULL_VERSION_TOKEN,
      useValue: environment.IS_FULL_VERSION,
    },
    {
      provide: RESOURCES_SERVICE,
      useClass: environment.IS_FULL_VERSION? ResourcesService : ResourcesEnService
    },
    {
      provide: LANGUAGES_TOKEN,
      useValue: environment.IS_FULL_VERSION ? LANGUAGES_RU : LANGUAGES_KA ,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private config: PrimeNGConfig,
    private readonly translate: TranslateService
  ) {

    this.translate.stream('PRIMENG').subscribe((res) => {
      this.config.setTranslation(res);
    });
  }
}
