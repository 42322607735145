import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class RegisterGuardChild  {
  constructor(private readonly authService: AuthService,private readonly translate: TranslateService) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authService.isAuth().then((isAuth) => {
      if (!isAuth && childRoute.queryParams.state === 'reg') {
        // console.log(search);
        // console.log(childRoute);
        this.authService.signIn<string>(state.url,this.translate.currentLang);
        return false;
      } else return true;
    });
  }
}
