import {Breakpoints} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {LayoutTypeService} from '@app-core/layout-service/layout.service';
import {ResourcesStateService} from '@app-states/resources-state.service';
import {Observable} from 'rxjs';
import {Announcement} from "@app-core/models";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  // public scrollBarWidth: number;
  public readonly header$!: Observable<{ header?:string,subheader?:string }>;
  public readonly isDesktop$: Observable<boolean>;
  public readonly isHomePage$: Observable<boolean>;

  public readonly breakpoints = Breakpoints;
  public readonly announcements$!: Observable<Announcement[]>;
  constructor(
    private readonly resourcesService: ResourcesStateService,
    private readonly layoutTypeService: LayoutTypeService
  ) {
    this.announcements$ = this.resourcesService.announcements$;
    // this.test$ = this.resourcesService.cities$;
    // this.scrollBarWidth = 0;

    // this.measureScrollbarWidth();
    this.header$ = this.layoutTypeService.header$;
    this.isDesktop$ = this.layoutTypeService.isDesktop$;
    this.isHomePage$ = this.layoutTypeService.isHomePage$;
  }
  // public measureScrollbarWidth() {
  //   // Add temporary box to wrapper
  //   let scrollbox = document.createElement('div');

  //   // Make box scrollable
  //   scrollbox.style.overflow = 'scroll';

  //   // Append box to document
  //   document.body.appendChild(scrollbox);

  //   // Measure inner width of box
  //   this.scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;

  //   // Remove box
  //   document.body.removeChild(scrollbox);
  // }

  // get width() {
  //   return this.scrollBarWidth;
  // }
  public ngOnInit(): void {
    // document.documentElement.style.setProperty(
    //   '--scrollbar-width',
    //   this.width + 'px'
    // );
    this.resourcesService.getCommonData();
    this.resourcesService.getAnnouncements();
  }
}
