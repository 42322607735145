<!-- <div class="flex flex-column"> -->
<!-- <div class="grid">
  <div class="col"> -->
<!-- <div class="wrapper"> -->
<!-- <p-scrollPanel
  [style]="{ width: 'calc(100vw + 0px)', height: 'calc(100vh + 0px)' }"
  styleClass="custombar1"
> -->
<!-- <ng-scrollbar [visibility]="'hover'"> -->
<header
  class="dark-background"
>
  <app-header
    [isDesktop]="isDesktop$ | myAsync"
    [headerValue]="header$ | myAsync"
  ></app-header>
<!--  <ng-container *ngIf="(isDesktop$ | myAsync) && (isHomePage$ | myAsync)">-->
<!--    <div class="grid justify-content-center container&#45;&#45;limited centerposition">-->
<!--      <div class="col-11">-->
<!--        <app-announcement-->
<!--          class="px-2"-->
<!--          [item]="item"-->
<!--          *ngFor="let item of announcements$ | async"-->
<!--        ></app-announcement>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-container>-->

  <!--  <h1 *ngIf="!(isDesktop$ | myAsync)">{{ header$ | myAsync | translate }}</h1>-->
</header>
<body class="dark-background">
  <router-outlet></router-outlet>
</body>
<footer class="dark-background">
  <app-footer></app-footer>
</footer>
<!-- </ng-scrollbar> -->
<!-- </p-scrollPanel> -->

<!-- </div> -->
<!-- </div>
</div> -->
<!-- </div> -->
<!-- <div class="wrapper">
  <div class="item1">Item 1</div>
  <div class="item2">Item 2</div>
  <div class="item3">Item 3</div>
  <div class="item4">Item 4</div>
</div> -->
