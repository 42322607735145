import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { from, map, Observable, tap } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class AuthGuard  {
  constructor(
    private readonly authService: AuthService,
    private readonly translate: TranslateService
  ) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return from(this.authService.isAuth()).pipe(
      tap((isAuth) => {
        if (!isAuth) {
          this.authService.signIn<string>(
            state.url,
            this.translate.currentLang
          );
        }
      }),
      map((isAuth) => isAuth)
    );
  }
}
