<div class="container--limited">
  <div class="app-header-wrapper">
    <div class="up-row">
      <div class="logo-wrapper">
        <a
          [routerLink]="'/'"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          ><img class="image" [src]="src"
        /></a>
        <div class="phone-wrapper">
          <span [innerHTML]="'PAGE.CONTACTS.MAIN_PHONE' | translate"></span>
        </div>
        <div class="utp-block">
          <span class="subheaderValue">
            {{ "PAGE.SEARCH.SEARCH_BLOCK.SUB_TITLE" | translate }}
          </span>
        </div>
      </div>

      <div class="right-block">
        <div
          class="flex flex-nowrap justify-content-end align-items-center xl:gap-3 gap-2"
        >
          <ng-container *ngIf="currencyData$ | async as currencyData">
            <p-dropdown
              *ngIf="currencyData.list.length > 1"
              class="currency-switcher"
              [options]="currencyData.list"
              [ngModel]="currencyData.currency"
              [styleClass]="'p-dropdown--custom'"
              (onChange)="changeCurrency($event)"
              optionLabel="label"
              optionValue="value"
              [showClear]="false"
            >
              <ng-template let-currency  pTemplate="selectedItem">
                <div class="currency-item ">
                  <span [class]="'flex line-height-normal'">
                    {{ currency.label }}
                  </span>
                </div>
              </ng-template>
              <ng-template let-currency pTemplate="item">
                <div class="currency-item flex">
                  <div>{{ currency.label }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </ng-container>

          <app-language-switcher
            *ngIf="currentLanguage$ | async as currentLanguage"
            (langChanged)="changeLang($event)"
            [currentLanguage]="currentLanguage"
          ></app-language-switcher>
          <ng-container *ngIf="isAuth | async; else notAuthBlock">
            <div class="logged-block">
              <button class="login-btn btn-gray-outline" [routerLink]="['/profile']">
                {{ "UI.MENU.PROFILE" | translate }}
              </button>
              <button class="login-btn btn-gray-outline" (click)="onLogout()">
                <i class="pi pi-sign-out"></i>
              </button>
            </div>
          </ng-container>
          <ng-template #notAuthBlock>
            <button class="login-btn btn-primary" (click)="onLogin()">
              {{ "UI.MENU.LOGIN" | translate }}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="down-row">
      <div class="header-wrapper" *ngIf="headerValue">
        <span class="headerValue" *ngIf="headerValue.header">
          {{ headerValue.header | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
