<router-outlet></router-outlet>
<p-toast
  [showTransitionOptions]="'700ms'"
  [hideTransitionOptions]="'700ms'"
  [showTransformOptions]="'translateX(100%)'"
>
    <ng-template let-message pTemplate="message">
      <div class="flex flex-column" style="flex: 1">
          <h4>{{message.summary}}</h4>
          <p>{{message.detail | translate}}</p>
      </div>
    </ng-template>
</p-toast>
<!--@if (!isProduction){-->
<!--  <button (click)="throwTestError()">Test Sentry Error</button>-->
<!--}-->
