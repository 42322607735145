import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Configs } from '@app-core/configs/configs';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular';

Sentry.init({
  dsn: Configs.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  environment: Configs.production ? 'prod' : 'dev',
  release: `${Configs.PROJECT}@${Configs.VERSION}`,
  tracesSampleRate: 1.0,
});

enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
