<div class="container--limited footer-wrapper">
  <div class="left-wrapper">
    <a
      class="logo"
      [routerLink]="'/'"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/images/aviaoperator_name.png" />
    </a>
    <div class="links">
      <a
        *ngFor="let item of itemsNav"
        [routerLink]="item.routerLink"
        routerLinkActive="active"
        class="link"
        [routerLinkActiveOptions]="{ exact: true }"
      ><i [class]="item.icon"></i> {{ item.label | translate }}</a
      >
    </div>
  </div>

  <div class="socials">
    <ng-container *ngFor="let item of itemsSocialNetwork">
      <a
        *isFull="true"
        [href]="item.url"
        class="icon"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      ><i [class]="item.icon"></i>
      </a>
    </ng-container>

  </div>
</div>
