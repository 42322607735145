import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FOUNDATION_YEAR, PHONE} from '@app-core/constants/common';
import {Language} from '@app-core/entities';
import {LayoutStateService} from "@app-states/layout-state.service";

import {TranslateService} from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';
import {combineLatest, Observable} from "rxjs";
import {AuthService} from 'src/app/auth/services/auth.service';
import {ICurrencySite} from "@app-core/constants";
import {map} from "rxjs/operators";
import {CurrencyEnum} from "@app-core/enums/currency.enum";
import {DropdownChangeEvent} from "primeng/dropdown";

const img = '/assets/images/aviaoperator.svg';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() public isDesktop: boolean;
  @Input() public headerValue: { header?:string,subheader?:string };
  public currentLanguage$: Observable<Language | null>;
  public currency$: Observable<CurrencyEnum> = this.layoutStateService.currency$;
  public currencyList$: Observable<ICurrencySite[]> = this.layoutStateService.currencyListBySite$;
  public currencyData$:Observable<{ currency:CurrencyEnum; list: ICurrencySite[] }> = combineLatest([this.currency$, this.currencyList$]).pipe(
    map(([currency, list]) => {
      return {
        currency,
        list,
      };
    })
  );
  public src = img;
  public items: MenuItem[];
  showAnnouncement = true;
  public isAuth: Promise<boolean>;

  public get ageCount(): { value: string } {
    return { value: (new Date().getFullYear() - FOUNDATION_YEAR).toString() };
  }
  constructor(
    private readonly translate: TranslateService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly layoutStateService:LayoutStateService
  ) {
    // this.currentLanguage = LANGUAGES[this.translate.currentLang];
    this.currentLanguage$ = this.layoutStateService.language$;
    this.items = [];
    this.isDesktop = true;
    this.headerValue = {};
    this.isAuth = this.authService.isAuth();
  }
  public toggle() {
    this.showAnnouncement = !this.showAnnouncement;
  }
  public ngOnInit(): void {}
  // public menuToggle() {
  //   this.menuOptionsFactory().then((res) => (this.items = res));
  // }
  public changeLang = (language: Language): void => {
    // this.translate.use(language.locale);
    this.layoutStateService.changeLanguage(language);
    // this.currentLanguage = language;
  };
  public changeCurrency = (currency: DropdownChangeEvent): void => {
    this.layoutStateService.changeCurrency(currency.value);
  }
  onLogin() {
    this.authService.signIn<string>(this.router.url, this.translate.currentLang);
  }

  onLogout() {
    this.authService.singOut<string>(this.router.url, this.translate.currentLang);
  }
  // register() {
  //   this.authService.register<string>(this.router.url);
  // }
  // private menuOptionsFactory = async () => {
  //   const result: MenuItem[] = [];
  //   const isAuth = await this.authService.isAuth();
  //
  //   if (!isAuth) {
  //     result.push(
  //       {
  //         label: this.translate.instant('UI.MENU.LOGIN'),
  //         command: () => {
  //           this.login();
  //         },
  //       }
  //       // {
  //       // label: 'Register',
  //       //   command: () => {
  //       //   this.register();
  //       // },
  //     // }
  //     );
  //   } else {
  //     result.push(
  //       {
  //         label: this.translate.instant('UI.MENU.LOGOUT'),
  //         command: () => {
  //           this.logout();
  //         },
  //       },
  //       {
  //         label: this.translate.instant('UI.MENU.PROFILE'),
  //         routerLink: '/profile',
  //       }
  //     );
  //   }
  //   return result;
  // };

  public onClickMenu() {

  }
}
