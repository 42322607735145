import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional,} from '@angular/core';
import {CompleteFunction} from '../models';
import {COMPLETE_SIGNOUT_TOKEN} from '../models/complete-sigout-token';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-logout-callback',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutCallbackComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    @Optional()
    @Inject(COMPLETE_SIGNOUT_TOKEN)
    private readonly completSingOutCallback?: CompleteFunction
  ) {}

  public async ngOnInit(): Promise<void> {
    const state: string = await this.authService.singOutComplete();
    if (this.completSingOutCallback) {
      // console.log(search);

      this.completSingOutCallback(state.includes('profile') ? '/' : state);
    }
  }
}
