import {ChangeDetectionStrategy, Component, Inject, OnInit, Optional,} from '@angular/core';
import {COMPLETE_AUTHENTICATE_TOKEN, CompleteFunction} from '../models';
import {AuthService} from '../services/auth.service';

@Component({
  selector: 'app-auth-callback',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    @Optional()
    @Inject(COMPLETE_AUTHENTICATE_TOKEN)
    private readonly completeAuthenticationCallback?: CompleteFunction
  ) {}

  public async ngOnInit(): Promise<void> {
    const state = await this.authService.completeAuthentication();

    if (this.completeAuthenticationCallback) {
      this.completeAuthenticationCallback(state);
    }
  }
}
