import {LocalizationApiService} from "@app-core/api-services/localization.service";
import { TranslateLoader } from '@ngx-translate/core';
import {Observable} from "rxjs";

class CustomTransLoader implements TranslateLoader {

  constructor(
    private readonly localizationApiService: LocalizationApiService,
  ) {}

  public getTranslation(lang: string): Observable<object> {
    return this.localizationApiService.getTranslationFromAssets(lang)
  }
}

export function customTranslateLoader(localizationApiService: LocalizationApiService) {
  return new CustomTransLoader(localizationApiService);
}
